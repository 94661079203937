 <template>
	<el-dialog 
		title="网点选择" 
		top="1vh" width="90%" 
		append-to-body 
		custom-class="el-dialog-s" 
		:visible.sync="is_show_in_page" 
		@close="page_close"
	>
		<div :style="{height:dialog_inner_height}">
			<!-- 搜索条件表单 -->
			<el-form class="ser_form" size="small"  label-width="90px">
				<el-form-item class="el_form_item" label="物流园">
					<el-input class="el_input" v-model="form.logistics_park_name" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="网点名称">
					<el-input class="el_input" v-model="form.name" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="联系人">
					<el-input class="el_input" v-model="form.link_man" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="联系电话">
					<el-input class="el_input" v-model="form.link_tel" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="备注">
					<el-input class="el_input" v-model="form.mark" clearable></el-input>
				</el-form-item>
				<el-form-item label-width="0">
					<el-button type="primary" @click="page_ser">查询</el-button>
				</el-form-item>
			</el-form>

			<!-- 表格 -->
			<div class="tab_height">
				<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
					<el-table-column label="添加/修改时间" width="175">
						<template slot-scope="scope">
							<div class="tab_line_item">添加: {{scope.row.create_time_text}}</div>
							<div class="tab_line_item">修改: {{scope.row.update_time_text}}</div>
						</template>
					</el-table-column>
					<el-table-column label="物流园信息">
						<template slot-scope="scope">
							<div class="tab_line_item">{{scope.row.logistics_park_info.city}}{{scope.row.logistics_park_info.county}}/{{scope.row.logistics_park_info.name}}</div>
							<div class="tab_line_item">
								<span v-if="scope.row.logistics_park_info.link_man">{{scope.row.logistics_park_info.link_man}}</span>
								<span v-if="scope.row.logistics_park_info.link_man && scope.row.logistics_park_info.link_tel">/</span>
								<span v-if="scope.row.logistics_park_info.link_tel">{{scope.row.logistics_park_info.link_tel}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="网点信息">
						<template slot-scope="scope">
							<div class="tab_line_item">{{scope.row.name}}</div>
							<div class="tab_line_item">
								<span v-if="scope.row.link_man">{{scope.row.link_man}}</span>
								<span v-if="scope.row.link_man && scope.row.link_tel">/</span>
								<span v-if="scope.row.link_tel">{{scope.row.link_tel}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="备注" prop="mark" width="200"></el-table-column>
					<el-table-column label="状态" prop="status_text" width="80"></el-table-column>
					
					<!-- 操作行 -->
					<el-table-column label="操作" width="80">
						<template slot-scope="scope">
							<el-button @click="choose(scope.row)" size="mini" type="text">选定</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<!-- 分页 -->
			<el-pagination 
				class="margin-top-xl" 
				@size-change="page_size_change" 
				@current-change="page_current_change" 
				:current-page="page.p" 
				:page-sizes="[10, 20, 30, 40]" 
				:page-size="page.num" 
				:total="list.total*1" 
				layout="total, sizes,prev, pager, next, jumper" 
			></el-pagination>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
			logistics_company_num:String
		},
		data() {
			return {

				is_show_in_page:false,
				dialog_inner_height:'',

				//搜索条件
				form: {
					logistics_park_name:'',
					name:'',
					link_man:'',
					link_tel:'',
					mark:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//监听页面高度
					this.dialog_inner_height=parseInt(document.body.clientHeight)-160+'px'
					window.onresize=()=>{
						this.dialog_inner_height=parseInt(document.body.clientHeight)-160+'px'
					}

					//打开弹出层
					this.is_show_in_page=true;

					//读取用户组数据
					this.get_page_data()
					
				}else this.is_show_in_page=false;
			}
		},
		methods: {

			//选定
			choose(item){

				//通知
				this.$emit('choosed',item);
			},

			//页面关闭时
			page_close(){

				//结束监听
				window.onresize=null;
			},

			//搜索
			page_ser(){
	
				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'logistics',
						ctr:'node_list_by_admin',
						is_get_logistics_park_info:1,
						status:1,
						logistics_company_num:this.logistics_company_num,
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//创建时间
							item.create_time_text=this.$my.other.totime(item.create_time);

							//上次修改时间
							item.update_time_text=this.$my.other.totime(item.update_time);

							//状态(1:正常,2:停用)
							switch(item.status){
								case '1':item.status_text="正常";break;
								case '2':item.status_text="停用";break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		width: 90%;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 40px);
	}
	.el_form_item{
		width: 16.667%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
	.el-dialog-s{
		z-index: 11;
		height:calc(100% - 2vh) !important;
	}
</style>
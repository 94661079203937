<template>
	<el-dialog title="添加线路" top="1vh" width="1080px" :visible.sync="is_show_in_page">
		
		<!-- 表单 -->
		<el-form class="form" label-width="100px">
			<div class="big_tit" style="margin:0">线路名称/物流公司</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="线路名称" style="width:320px">
					<el-input class="el_inner_width" v-model="form.name" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="公司名称" style="width:320px">
					<el-input class="el_inner_width" v-model="logistice_company_choose.name" disabled>
						<el-button @click="logistice_company_choose_open()" slot="append">选择</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="线路备注" style="width:329px">
					<el-input class="el_inner_width" v-model="form.mark" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">网点选择</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货网点(单)" style="width:100%">
					<el-input class="el_inner_width" v-model="node_choose.case_node_info_text" disabled>
						<el-button @click="node_choose_open('case')" slot="append">选择</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="中转网点(多)" style="width:100%">
					<div style="display:flex;width:100%;">
						<div class="el_inner_width inner_div">
							<el-tag 
								style="display: inline-block;margin:4px"
								v-for="(node,index) in node_choose.transit_nodes" 
								:key="index" closable @close="transit_node_del(index)" 
							>{{`${node.logistics_park_info.city}${node.logistics_park_info.county} / ${node.logistics_park_info.name} / ${node.name}`}}</el-tag>
						</div>
						<el-button @click="node_choose_open('transit')" style="height:38px;background-color:#F5F7FA">选择</el-button>
					</div>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="卸货网点(单)" style="width:100%">
					<el-input class="el_inner_width" v-model="node_choose.aim_node_info_text" disabled>
						<el-button @click="node_choose_open('aim')" slot="append">选择</el-button>
					</el-input>
				</el-form-item>
			</div>


			<div class="big_tit">线路给平台的报价</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="重量单价" style="width:320px">
					<el-input class="el_inner_width" v-model="form.weight_price" type="number" clearable>
						<template slot="append">元/吨</template>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="体积单价" style="width:320px">
					<el-input class="el_inner_width" v-model="form.volume_price" type="number" clearable>
						<template slot="append">元/方</template>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="件数单价" style="width:329px">
					<el-input class="el_inner_width" v-model="form.number_price" type="number" clearable>
						<template slot="append">元/件</template>
					</el-input>
				</el-form-item>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub">确定添加</el-button>
			<el-button type="danger" @click="clear">清空数据</el-button>
		</div>

		<!-- 物流公司选择弹出层 -->
		<logisticsCompanyChoose 
			:is_show="logistice_company_choose.is_show" 
			@choosed="logistice_company_choosed" 
		></logisticsCompanyChoose>

		<!-- 选择网点 -->
		<nodeChoose 
			:is_show="node_choose.is_show" 
			:logistics_company_num="this.logistice_company_choose.logistics_company_num" 
			@choosed="node_choosed" 
		></nodeChoose>

	</el-dialog>
</template>
<script>
	import nodeChoose from '../node/choose.vue'
	import logisticsCompanyChoose from '../company/choose.vue'
	export default {
		props:{
			is_show:Number,
			logistics_company_list:Array,
		},
		components:{
			nodeChoose,
			logisticsCompanyChoose
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,

				//表单
				form:{
					name:'',
					weight_price:'',
					volume_price:'',
					number_price:'',
					mark:'',
				},

				//物流公司选择
				logistice_company_choose:{
					is_show:0,
					name:'',
					logistics_company_num:'',
				},

				//网点选择
				node_choose:{
					type:'',
					is_show:0,
					case_node_info_text:'',
					case_node_num:'',
					transit_nodes:[],
					aim_node_info_text:'',
					aim_node_num:'',
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;

					//初始化
					this.init();
					
				}else this.is_show_in_page=false;
			}
		},
		methods: {

			//提交
			sub(){

				if(!this.form.name.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少线路名称'
					});
					return;
				}

				if(!this.logistice_company_choose.logistics_company_num.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少物流公司信息'
					});
					return;
				}

				if(!this.$my.check.is_num(this.form.weight_price)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少重量单价'
					});
					return;
				}

				if(!this.$my.check.is_num(this.form.volume_price)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少体积单价'
					});
					return;
				}

				if(!this.$my.check.is_num(this.form.number_price)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少件数单价'
					});
					return;
				}

				if(!this.node_choose.case_node_num.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少装货网点'
					});
					return;
				}

				if(!this.node_choose.aim_node_num.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少卸货网点'
					});
					return;
				}

				//中转网点
				let transit_node_nums=[];
				for(var transit_node_item of this.node_choose.transit_nodes){
					transit_node_nums.push(transit_node_item.logistics_node_num);
				}

				//提交数据
				this.$my.net.req({
					data:{
						mod:'logistics',
						ctr:'route_add_by_admin',
						logistics_company_num:this.logistice_company_choose.logistics_company_num,
						case_node_num:this.node_choose.case_node_num,
						aim_node_num:this.node_choose.aim_node_num,
						transit_node_nums:transit_node_nums,
						...this.form,
					},
					callback:(data)=>{

						//添加成功
						this.$my.other.confirm({
							content:"添加成功,是否前往列表页?",
							confirm:()=>{

								//通知
								this.$emit("added");
							}
						});
					}
				});
			},

			//打开物流公司选择界面
			logistice_company_choose_open(type){
				
				//打开界面
				this.logistice_company_choose.is_show++
			},
			logistice_company_choosed(obj){

				//置入数据
				this.logistice_company_choose.name=obj.name
				this.logistice_company_choose.logistics_company_num=obj.logistics_company_num

				//关闭弹出层
				this.logistice_company_choose.is_show=0
			},

			//打开网点选择界面
			node_choose_open(type){
				if(!this.logistice_company_choose.logistics_company_num){
					this.$my.other.msg({
						type:"warning",
						str:'请先确定公司'
					});
					return;
				}
				this.node_choose.is_show++
				this.node_choose.type=type
			},
			node_clear(){
				this.node_choose.case_node_info_text=''
				this.node_choose.case_node_num=''
				this.node_choose.transit_nodes=[]
				this.node_choose.aim_node_info_text=''
				this.node_choose.aim_node_num=''
			},
			transit_node_del(index){
				this.node_choose.transit_nodes.splice(index,1);
			},
			node_choosed(obj){

				switch(this.node_choose.type){

					case 'case'://装货网点
						for(var transit_node_item of this.node_choose.transit_nodes){
							if(obj.logistics_node_num==transit_node_item.logistics_node_num){
								this.$my.other.msg({
									type:"warning",
									str:'装货网点不能与中转网点相同'
								});
								return;
							}
						}
						if(obj.logistics_node_num==this.node_choose.aim_node_num){
							this.$my.other.msg({
								type:"warning",
								str:'装货网点不能与到货网点相同'
							});
							return;
						}
						this.node_choose.case_node_num=obj.logistics_node_num
						this.node_choose.case_node_info_text=`${obj.logistics_park_info.city}${obj.logistics_park_info.county} / ${obj.logistics_park_info.name} / ${obj.name}`;
						break;

					case 'transit'://中转网点
						if(obj.logistics_node_num==this.node_choose.case_node_num){
							this.$my.other.msg({
								type:"warning",
								str:'中转网点不能与装货网点相同'
							});
							return;
						}
						for(var transit_node_item of this.node_choose.transit_nodes){
							if(obj.logistics_node_num==transit_node_item.logistics_node_num){
								this.$my.other.msg({
									type:"warning",
									str:'中转网点重复'
								});
								return;
							}
						}
						if(obj.logistics_node_num==this.node_choose.aim_node_num){
							this.$my.other.msg({
								type:"warning",
								str:'中转网点不能与到货网点相同'
							});
							return;
						}

						//置入
						this.node_choose.transit_nodes.push(obj)
						break;

					case 'aim'://卸货网点
						for(var transit_node_item of this.node_choose.transit_nodes){
							if(obj.logistics_node_num==transit_node_item.logistics_node_num){
								this.$my.other.msg({
									type:"warning",
									str:'卸货网点不能与中转网点相同'
								});
								return;
							}
						}
						if(obj.logistics_node_num==this.node_choose.case_node_num){
							this.$my.other.msg({
								type:"warning",
								str:'卸货网点不能与装货网点相同'
							});
							return;
						}
						this.node_choose.aim_node_num=obj.logistics_node_num
						this.node_choose.aim_node_info_text=`${obj.logistics_park_info.city}${obj.logistics_park_info.county} / ${obj.logistics_park_info.name} / ${obj.name}`;
						break;
				}

				//关闭弹出层
				this.node_choose.is_show=0
			},

			//初始化
			init(){

				//物流公司选择
				this.logistice_company_choose.is_show=0
				this.logistice_company_choose.name=''
				this.logistice_company_choose.logistics_company_num=''

				//表单
				this.form.name=''
				this.form.weight_price=''
				this.form.volume_price=''
				this.form.number_price=''
				this.form.mark=''

				this.node_clear()
			},

			//清空本页数据
			clear(){

				this.$my.other.confirm({
					content:"点击确定清空本页数据",
					confirm:()=>{

						//初始化
						this.init();
					}
				});
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.bottom_btns{
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: calc(100% - 60px);
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form{
		height: calc(100% - 100px);
		overflow-y:auto;
		padding-bottom:75px;
		margin-top:-20px;
	}

	.inner_div{
		width:85.977%;
		height: 78px;
		line-height:39px;
		border: 1px solid #E4E7ED;
		background-color: #F5F7FA;
		border-radius: 4px;
		display:flex;
		flex-wrap:wrap;
	}

	.page{
		position:relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

</style>
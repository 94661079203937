 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="物流公司">
				<el-input class="el_input" v-model="form.logistics_company_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="线路名称">
				<el-input class="el_input" v-model="form.name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="装货地网点">
				<el-input class="el_input" v-model="form.case_node_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="目的地网点">
				<el-input class="el_input" v-model="form.aim_node_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="中转地网点">
				<el-input class="el_input" v-model="form.transit_node_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注">
				<el-input class="el_input" v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="正常" value="1"></el-option>
					<el-option label="停用" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="success" @click="add_view_open">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="添加/修改时间" width="175">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.name}}</div>
						<div class="tab_line_item">添加: {{scope.row.create_time_text}}</div>
						<div class="tab_line_item">修改: {{scope.row.update_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="物流公司信息" width="160">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.logistics_company_info.name}}</div>
						<div class="tab_line_item">
							<span v-if="scope.row.logistics_company_info.link_man">{{scope.row.logistics_company_info.link_man}}</span>
							<span v-if="scope.row.logistics_company_info.link_man && scope.row.logistics_company_info.link_tel">/</span>
							<span v-if="scope.row.logistics_company_info.link_tel">{{scope.row.logistics_company_info.link_tel}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="装货地网点" width="260">
					<template slot-scope="scope">
						<div class="tab_line_item">园区:{{scope.row.case_node_info.logistics_park_info.name}}/网点:{{scope.row.case_node_info.name}}</div>
						<div class="tab_line_item">地址:{{scope.row.case_node_info.logistics_park_info.county}}{{scope.row.case_node_info.logistics_park_info.addr}}</div>
						<div class="tab_line_item">
							<span v-if="scope.row.case_node_info.link_man">{{scope.row.case_node_info.link_man}}</span>
							<span v-if="scope.row.case_node_info.link_man && scope.row.case_node_info.link_tel">/</span>
							<span v-if="scope.row.case_node_info.link_tel">{{scope.row.case_node_info.link_tel}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="中转地网点">
					<template slot-scope="scope">
						<el-tag 
							v-for="(transit_node,index) in scope.row.transit_nodes" 
							:key="index"
						>{{transit_node.logistics_park_info.city}}{{transit_node.logistics_park_info.county}}/{{transit_node.logistics_park_info.name}}/{{transit_node.name}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="目的地网点" width="260">
					<template slot-scope="scope">
						<div class="tab_line_item">园区:{{scope.row.aim_node_info.logistics_park_info.name}}/网点:{{scope.row.aim_node_info.name}}</div>
						<div class="tab_line_item">地址:{{scope.row.aim_node_info.logistics_park_info.county}}{{scope.row.aim_node_info.logistics_park_info.addr}}</div>
						<div class="tab_line_item">
							<span v-if="scope.row.aim_node_info.link_man">{{scope.row.aim_node_info.link_man}}</span>
							<span v-if="scope.row.aim_node_info.link_man && scope.row.aim_node_info.link_tel">/</span>
							<span v-if="scope.row.aim_node_info.link_tel">{{scope.row.aim_node_info.link_tel}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="单价" width="130">
					<template slot-scope="scope">
						<div class="tab_line_item" v-if="scope.row.weight_price>0" >重量: {{scope.row.weight_price}}元/吨</div>
						<div class="tab_line_item" v-if="scope.row.volume_price>0">体积: {{scope.row.volume_price}}元/方</div>
						<div class="tab_line_item" v-if="scope.row.number_price>0">件数: {{scope.row.number_price}}元/件</div>
					</template>
				</el-table-column>
				<el-table-column label="备注" prop="mark" width="130"></el-table-column>
				<el-table-column label="状态" prop="status_text" width="60"></el-table-column>
				
				<!-- 操作行 -->
				<el-table-column label="操作" width="120">
					<template slot-scope="scope">
						<el-button @click="edit_view_open(scope.row)" size="mini" type="text">修改</el-button>
						<el-button v-if="scope.row.status==1" @click="status_change(scope.row,2)" size="mini" type="text">停用</el-button>
						<el-button v-if="scope.row.status==2" @click="status_change(scope.row,1)" size="mini" type="text">启用</el-button>
						<el-button @click="del(scope.row,3)" size="mini" type="text">删除</el-button>
						<!-- <auth-button @click="price_for_customer_open(scope.row)" size="mini" type="text" style="margin:0">对客户报价</auth-button> -->
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 修改弹出层 -->
		<edit 
			:is_show="edit.is_show" 
			:data="edit.data" 
			@edited="edit_view_close" 
		></edit>

		<!-- 添加弹出层 -->
		<add 
			:is_show="add.is_show" 
			@added="add_view_close"
		></add>

	</div>
</template>

<script>
	import add from './add.vue' 
	import edit from './edit.vue' 
	export default {
		components:{
			add,
			edit,
		},
		data() {
			return {

				//搜索条件
				form: {
					logistics_company_name:'',
					name:'',
					case_node_name:'',
					aim_node_name:'',
					transit_node_name:'',
					status:'',
					mark:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//添加弹窗
				add:{
					is_show:0,
				},

				//修改弹窗
				edit:{
					data:{},
					is_show:0,
				},

				//线路报价弹窗
				price_for_customer:{
					route_info:{},
					is_show:0,
				},
			}
		},
		created() {
			
			//读取用户组数据
			this.get_page_data()
		},
		methods: {

			//状态变更
			status_change(item,new_status){

				//提示内容
				switch(new_status){
					case 1:var confirm_content="点击确定启用此项";break;
					case 2:var confirm_content="点击确定停用此项";break;
				}

				//询问
				this.$my.other.confirm({
					content:confirm_content,
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'logistics',
								ctr:'route_edit_by_admin',
								logistics_route_num:item.logistics_route_num,
								status:new_status
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							},
						});
					}
				});
			},

			//删除
			del(item){

				//询问
				this.$my.other.confirm({
					content:"是否删除此项",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'logistics',
								ctr:'route_del_by_admin',
								logistics_route_num:item.logistics_route_num,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							},
						});
					}
				});
			},

			//添加页面
			add_view_open(){
				this.add.is_show++
			},
			add_view_close(){
				this.add.is_show=0
				this.get_page_data()
			},

			//打开用户组修改页面
			edit_view_open(item){
				this.edit.data=item
				this.edit.is_show++
			},
			edit_view_close(item){
				this.edit.is_show=0
				this.get_page_data()
			},
			
			//搜索
			page_ser(){
	
				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'logistics',
						ctr:'route_list_by_admin',
						is_get_logistics_company_info:1,
						is_get_case_node_info:1,
						is_get_case_node_park_info:1,
						is_get_aim_node_info:1,
						is_get_aim_node_park_info:1,
						is_get_transit_nodes:1,
						is_get_transit_nodes_park_info:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//创建时间
							item.create_time_text=this.$my.other.totime(item.create_time);

							//上次修改时间
							item.update_time_text=this.$my.other.totime(item.update_time);

							//状态
							switch(item.status){
								case '1':item.status_text="正常";break;
								case '2':item.status_text="停用";break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		width: 90%;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>